import React from "react"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import education from "../../data/education.json"
import experience from "../../data/work-experience.json"
import { format } from "date-fns"
import compareAsc from "date-fns/compareAsc"
import parse from "date-fns/parse"

export const Icon = ({type}) => {
  if(type.toLowerCase() === "work") {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill:"white"}}>
        <path d="M12.23 15.5c-6.801 0-10.367-1.221-12.23-2.597v9.097h24v-8.949c-3.218 2.221-9.422 2.449-11.77 2.449zm1.77 2.532c0 1.087-.896 1.968-2 1.968s-2-.881-2-1.968v-1.032h4v1.032zm-14-8.541v-2.491h24v2.605c0 5.289-24 5.133-24-.114zm9-7.491c-1.104 0-2 .896-2 2v2h2v-1.5c0-.276.224-.5.5-.5h5c.276 0 .5.224.5.5v1.5h2v-2c0-1.104-.896-2-2-2h-6z"></path>
      </svg>
    )
  } else {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill:"white"}}>
        <path d="M20 12.875v5.068c0 2.754-5.789 4.057-9 4.057-3.052 0-9-1.392-9-4.057v-6.294l9 4.863 9-3.637zm-8.083-10.875l-12.917 5.75 12 6.5 11-4.417v7.167h2v-8.25l-12.083-6.75zm13.083 20h-4c.578-1 1-2.5 1-4h2c0 1.516.391 2.859 1 4z"/>
      </svg>
    )
  }
}

export const TimelineElement = ({ data }) => {

  const startDate = format(new Date(data.startDate), "MMM yyyy");
  const endDate = data.endDate.toLowerCase() === "present" 
    ? "Present" : format(new Date(data.endDate), "MMM yyyy");

  return(
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      date={startDate + " - " + endDate}
      iconStyle={{background: data.type.toLowerCase() === "work"? '#FB2F6D' : '#16BAC5', color: '#fff'}}
      icon={<Icon type={data.type} />}
    >
      <h3 className="vertical-timeline-element-title">{data.title}</h3>
      <h4 className="vertical-timeline-element-subtitle">{data.name}</h4>
      <h5 style={{fontSize: "18x", color: "grey"}}><i>{data.type==="Work"?data.subject:null}</i></h5>
      { data.desc === "" ? "" : <p><i>{data.desc}</i></p> }
    </VerticalTimelineElement>
  )
}

export default () => {
  const rawEvents = education.concat(experience).filter((data) => data.relevance < 3);
  let events = [];

  for(let i=0; i<rawEvents.length; i++){
    const parent = rawEvents[i];
    for(let j=0; j<parent.events.length; j++){
      let event = parent.events[j];
      event.name = parent.name;
      event.type = parent.type;
      event.subject = parent.subject;
      events.push(event);
    }
  }

  return(
    <VerticalTimeline>
      {
        events.sort((a, b) => {
          var resultA = parse(
            a.startDate,
            "yyyy-MM",
            new Date()
          )
          var resultB = parse(
            b.startDate,
            "yyyy-MM",
            new Date()
          )
          return compareAsc(resultB, resultA)
        }).map((data) => <TimelineElement data={data} key={data.startDate + " - " + data.endDate} />)
      }
    </VerticalTimeline>
  )
}