import React from "react"
import "../../styles/about/skills.sass"
import loadable from '@loadable/component'
import { CSSTransition, TransitionGroup } from "react-transition-group"

const SkillBar = loadable(() => import('react-skillbars'))

export const SkillBarContainer = ({data, pos}) => (
  <div className="col-12 col-md-6 pb-5 px-lg-5 px-md-3 px-sm-5" >
    <h4 className="pt-4 pb-3 text-center d-none d-md-block" style={{color: "#666666"}}>
      <i>{pos === 1 ? "Front-end" : "Back-end"}</i>
    </h4>
    <SkillBar 
      skills={data} 
      animationDelay={pos === 1 ? 0 : 500} 
      animationDuration={1500}
      colors={
        {bar: '#F09D51',
          title: {
            text: '#fff',
            background: '#ED8C31'
          }
        }
      }
    />
  </div>
)

export default class Skills extends React.Component {
  state = {
    focus: "Front-end"
  }

  handleClick = (focus) => {
      this.setState({
        focus
      });
    }

  render() {
    return(
      <div className="row container-small skills">
        <h2 className="col-12 pt-4 pb-0 mb-0">Skills</h2>
        <div className="col-12 pb-3 px-0 text-center d-md-none" style={{position: "relative"}}>
          <div className="row justify-content-center col-12 m-0" style={{height: "80px"}}>
            <button className={"btn " + (this.state.focus === "Front-end" ? "active" : "")} onClick={() => this.handleClick("Front-end")}><i>Front-end</i></button>
            <button className={"btn " + (this.state.focus === "Back-end" ? "active" : "")} onClick={() => this.handleClick("Back-end")}><i>Back-end</i></button>
          </div>
          <TransitionGroup>
            <CSSTransition
              key={this.state.focus}
              timeout={1000}
              classNames="messageout"
            >
              <SkillBarContainer 
                data={this.state.focus === "Front-end" ? this.props.data.frontEnd : this.props.data.backEnd} 
                pos={1} 
                key={this.state.focus} 
              />
            </CSSTransition>
          </TransitionGroup>
          
        </div>
        <div className="row m-0 p-0 d-none d-md-flex">
          <SkillBarContainer data={this.props.data.frontEnd} pos={1} key="Large-front-end" />
          <SkillBarContainer data={this.props.data.backEnd} pos={2} key="Large-back-end" />
        </div>
      </div>
    )
  }
}