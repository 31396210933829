import React from "react"
import image from "../styles/about/image.module.sass"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'react-vertical-timeline-component/style.min.css'
import { Link } from "gatsby"
import Timeline from "../components/about/timeline"
import Skills from "../components/about/skills"
import skillsArray from "../data/skills.json"

const AboutPage = () => (
  <div style={{overflowX: "hidden"}}>
    <Layout>
      <SEO title="About"/>
      <div className="col-12 py-5 my-5"/>
      <div className="row container-small is-grey">
        <div className="col-12 " 
        style={{
          textAlign: "center",
          margin: '0 auto',
          maxWidth: 1100,
          padding: '0px 1.0875rem 1.45rem',
          paddingTop: 0,
        }}>
          <div className={image.headshotContainer}>
            <img src={require("../images/headshot.jpg")} alt="Headshot of the website author" className={image.headshot}/>
          </div>
          <h1 className="pt-2">Hey! I'm Jake</h1>
          <p className="pt-1 mb-2">
            I am an ambitious software engineer with a First-Class Honours in Computer Science from the University of
            Brighton. I am currently working as an Android Engineer at American Express.
          </p>
          <p className="pb-3">
            I am constantly looking for the next thing to learn, and for that reason I have created this website as a
            platform to document the progress, learnings and struggles that I encounter and overcome throughout the
            many projects I intend to work on over the next few years - so watch this space!
          </p>
          <Link to="/cv" className="btn btn-primary px-5 py-2">
            View CV
          </Link>
        </div>
      </div>
      <Skills data={skillsArray} />
      <div className="row container-small is-grey">
        <div className="col-12" 
        style={{
          margin: '0 auto',
          maxWidth: 1100,
          padding: '0px 1.0875rem',
          paddingTop: 0,
        }}>
          <h2 className="pt-4 pb-3">My Journey</h2>
          <Timeline />
        </div>
      </div>
    </Layout>
  </div>
)

export default AboutPage
